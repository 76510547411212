@if (currentTile) {
  <div
    class="grid grid-cols-1 grid-rows-[80px,1fr,80px,auto] rounded-md border-2 border-gray-300"
  >
    <div class="flex items-center justify-center border-b border-gray-300">
      <div class="basis-1/3"></div>
      <div
        class="flex items-center justify-center flex-1 gap-5 text-2xl text-[#5f76a5] p-2.5"
      >
        <img
          class="w-[30px]"
          src="/assets/img/buttons/Reishauer_Icon-Upload.svg"
        />

        @if (editMode) {
          <div>{{ 'media.edit.title' | translate }}</div>
        } @else {
          <div>{{ 'media.upload.title' | translate }}</div>
        }
      </div>
      <div class="basis-1/3 flex items-center justify-end mr-5 text-2xl">
        @if (!editMode) {
          <div>{{ currentTileIndex + 1 }} / {{ tiles.length }}</div>
        }
      </div>
    </div>
    <div class="grid grid-cols-[60%,40%]">
      <div
        class="grid grid-cols-1 max-h-[600px] gap-5 items-center justify-center"
        [ngClass]="{
          'grid-rows-[100px,1fr]': editMode,
          'grid-rows-1': !editMode,
        }"
      >
        @if (editMode) {
          <div class="flex flex-col items-center justify-center">
            <rh-media-drag-and-drop-area
              class="w-[80%] px-5 pt-5"
              [multiple]="false"
              (filesDroppedEvent)="onFilesDropped($event)"
            />
          </div>
        }
        <div class="flex flex-col items-center justify-center">
          @if (showConfirmationMenu) {
            <div>
              {{ 'media.upload.confirmationText' | translate }}
            </div>
            <div>
              {{ 'media.upload.confirmationDescription' | translate }}
            </div>
            <div class="flex gap-5 mt-5">
              <rh-button
                [text]="'media.edit.buttons.dismiss' | translate"
                [textColor]="'#DF433F'"
                [naturalMode]="true"
                imageUrl="/assets/icons/Reishauer_Icon-Dismiss.svg"
                (buttonClick)="dismissFileDrop()"
              ></rh-button>
              <rh-button
                [text]="'media.edit.buttons.confirm' | translate"
                [textColor]="'#7EAE39'"
                [naturalMode]="true"
                [disabled]="!isValid"
                imageUrl="/assets/icons/Reishauer_Icon-Confirm.svg"
                (buttonClick)="confirmFileDrop()"
              ></rh-button>
            </div>
          } @else {
            @if (isVideo) {
              <video
                #videoElement
                class="object-contain object-center px-5 pb-10 max-h-[460px]"
                crossorigin="anonymous"
                type="video/mp4"
                preload="auto"
                [style.object-fit]="'cover'"
                [controls]="false"
                [autoplay]="false"
                [loop]="false"
                [src]="currentTile.displayImageUrl"
              ></video>
            } @else {
              <img
                class="object-contain object-center px-5 pb-10 max-h-[460px]"
                [src]="
                  currentTile.displayImageUrl +
                  '?randomRefreshKey=' +
                  randomRefreshKey
                "
              />
            }
          }
        </div>
      </div>
      <div class="flex flex-col p-5 gap-5">
        <rh-textbox
          class="w-full"
          [title]="'media.metadataEditor.name' | translate"
          [(value)]="currentTile.title"
        ></rh-textbox>
        <rh-media-asset-category-dropdown
          class="w-full"
          [title]="'media.metadataEditor.category' | translate"
          [categories]="categories"
          [userName]="userName"
          [(model)]="currentTile.category"
          (modelChange)="onCategoryChanged($event)"
          (saveClicked)="saveCategory($event)"
          (deleteClicked)="deleteCategory($event)"
        ></rh-media-asset-category-dropdown>
        <rh-textbox
          class="w-full"
          [title]="'media.metadataEditor.author' | translate"
          [(value)]="currentTile.author"
          [disabled]="true"
        ></rh-textbox>
        <rh-textbox
          class="w-full"
          [title]="'media.metadataEditor.uploaded' | translate"
          [value]="(currentTile.uploaded | date: 'short') || undefined"
          [disabled]="true"
        ></rh-textbox>
        <div class="w-full">
          <div
            class="text-[11px] font-brand-regular text-brand-blue-400 uppercase"
          >
            {{ 'media.metadataEditor.private' | translate }}
          </div>
          <rh-checkbox [(value)]="currentTile.private"></rh-checkbox>
        </div>
      </div>
    </div>
    <div
      class="flex items-center justify-center gap-2 border-t border-gray-300"
    >
      @if (editMode) {
        <rh-button
          [text]="'media.edit.buttons.cancel' | translate"
          [textColor]="'#DF433F'"
          [naturalMode]="true"
          imageUrl="/assets/icons/Reishauer_Icon-Dismiss.svg"
          (buttonClick)="cancel()"
        ></rh-button>
        <rh-button
          [text]="'media.edit.buttons.save' | translate"
          [textColor]="'#7EAE39'"
          [naturalMode]="true"
          [disabled]="!isValid"
          imageUrl="/assets/icons/Reishauer_Icon-Confirm.svg"
          (buttonClick)="save(currentTile)"
        ></rh-button>
      } @else {
        <rh-button
          [text]="'media.edit.buttons.dismiss' | translate"
          [textColor]="'#DF433F'"
          [naturalMode]="true"
          imageUrl="/assets/icons/Reishauer_Icon-Dismiss.svg"
          (buttonClick)="dismissAsset(currentTile)"
        ></rh-button>
        <rh-button
          [text]="'media.edit.buttons.confirm' | translate"
          [textColor]="'#7EAE39'"
          [naturalMode]="true"
          [disabled]="!isValid"
          imageUrl="/assets/icons/Reishauer_Icon-Confirm.svg"
          (buttonClick)="confirmAsset(currentTile.imageUrl)"
        ></rh-button>
      }
    </div>
    <div class="flex flex-col border-t border-gray-300 p-5">
      <h1 class="text-brand-blue-550">
        {{ 'media.referencedPresentations' | translate }}
      </h1>
      @let referenceCourses = referenceCourses$ | async;
      @if (referenceCourses && referenceCourses.length === 0) {
        <span>{{ 'media.noReferencedPresentations' | translate }}</span>
      }
      @for (referenceCourse of referenceCourses; track referenceCourse) {
        <span>- {{ referenceCourse }}</span>
      }
    </div>
  </div>
}
