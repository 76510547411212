import { AsyncPipe, NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadingModule } from '@myreishauer/loading';
import { selectIsLoadingOrSaving } from '../../../shared/store/core/core.selectors';
import { DragAndDropFilesDirective } from '../../course-list/directives/drag-and-drop-files.directive';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'rh-media-drag-and-drop-area',
  templateUrl: './media-drag-and-drop-area.component.html',
  styleUrls: ['./media-drag-and-drop-area.component.scss'],
  standalone: true,
  imports: [DragAndDropFilesDirective, NgClass, LoadingModule, AsyncPipe, TranslatePipe],
})
export class MediaDragAndDropAreaComponent {
  private store = inject(Store);
  protected loadingOrSaving$ = this.store.select(selectIsLoadingOrSaving);

  @ViewChild('multiFileUpload')
  private multiFileUpload!: ElementRef;

  @Input()
  public multiple = true;

  @Output()
  public filesDroppedEvent: EventEmitter<File[]> = new EventEmitter();

  protected active = false;

  protected onStatusChanged(status: boolean): void {
    this.active = status;
  }

  protected onFilesDropped(files: FileList): void {
    this.filesDroppedEvent.emit(Array.from(files));
  }

  protected onFilesSelected(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement?.files && inputElement.files.length > 0) {
      this.onFilesDropped(inputElement.files);
    }
  }

  protected selectFiles(): void {
    this.multiFileUpload?.nativeElement?.click();
  }
}
