import { MediaAssetCategory } from '../../../core/models/media-asset-category.model';
import { MediaAsset } from '../../../core/models/media-asset.model';

export interface MediaState {
  isLoading?: boolean;
  progressUpdate?: number;
  assets?: MediaAsset[];
  temporaryAssets: MediaAsset[];
  error?: string;
  editors: string[];
  mediaAssetCategories?: MediaAssetCategory[];
  copySlideSuccessMessage?: string;
  referencedCourses?: string[];
}

export const initialState: MediaState = {
  editors: [],
  temporaryAssets: [],
};
