import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MediaAssetCategory } from '../../../core/models/media-asset-category.model';
import { MediaAsset } from '../../../core/models/media-asset.model';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private http = inject(HttpClient);

  GetMediaAssets(
    mediaType: string,
    searchText: string,
    categoryId: number,
    timeFrame: number,
    editorId: string,
    privateOnly: boolean,
    sortBy: string,
    sortDir: string,
  ): Observable<MediaAsset[]> {
    return this.http
      .get<
        MediaAsset[]
      >(`${environment.backendApiUrl}/GetMediaAssets?code=${environment.apiKey}&mediaType=${mediaType}&searchText=${searchText}&categoryId=${categoryId}&timeFrame=${timeFrame}&editorId=${editorId}&privateOnly=${privateOnly}&sortBy=${sortBy}&sortDir=${sortDir}`)
      .pipe(
        map((response: MediaAsset[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetMediaAssetEditors(): Observable<string[]> {
    return this.http
      .get<
        string[]
      >(`${environment.backendApiUrl}/GetMediaAssetEditors?code=${environment.apiKey}`)
      .pipe(
        map((response: string[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveMediaAssets(assets: MediaAsset[]): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/SaveMediaAssets?code=${environment.apiKey}`,
        { mediaAssets: assets },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteMediaAsset(assetId: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteMediaAsset?code=${environment.apiKey}&id=${assetId}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveTemporaryMediaAsset(files: File[]): Observable<HttpEvent<object>> {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append(`file${i}`, file);
    }

    return this.http
      .post<string[]>(
        `${environment.backendApiUrl}/SaveTemporaryMediaAsset?code=${environment.apiKey}`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteTemporaryMediaAsset(mediaAssetId: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteTemporaryMediaAsset/${mediaAssetId}?code=${environment.apiKey}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetMediaAssetCategories(): Observable<MediaAssetCategory[]> {
    return this.http
      .get<
        MediaAssetCategory[]
      >(`${environment.backendApiUrl}/GetMediaAssetCategories?code=${environment.apiKey}`)
      .pipe(
        map((response: MediaAssetCategory[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveMediaAssetCategory(
    category: MediaAssetCategory,
  ): Observable<MediaAssetCategory> {
    return this.http
      .post<{
        result: string;
        category: MediaAssetCategory;
      }>(
        `${environment.backendApiUrl}/SaveMediaAssetCategory?code=${environment.apiKey}`,
        { category },
      )
      .pipe(
        map((response) => {
          return response.category;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteMediaAssetCategory(categoryId: number): Observable<object> {
    return this.http
      .delete(
        `${environment.backendApiUrl}/DeleteMediaAssetCategory?id=${categoryId}&code=${environment.apiKey}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetMediaAssetReferences(imageUrl: string): Observable<string[]> {
    return this.http
      .get<
        string[]
      >(`${environment.backendApiUrl}/GetMediaAssetReferences?imageUrl=${imageUrl}&code=${environment.apiKey}`)
      .pipe(
        map((response: string[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  UpdateMediaBlobAssets(files: File[]): Observable<object> {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append(`file${i}`, file);
    }

    return this.http
      .put(
        `${environment.backendApiUrl}/UpdateMediaBlobAssets?code=${environment.apiKey}`,
        formData,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
