<div class="medialibrary-container">
  <div class="left bg-brand-blue-550">
    <div class="title">
      <span class="logo-container">
        <img src="assets/img/Reishauer_Icon_SlidesWhite.svg" alt="MediaIcon" />
      </span>
      <span>{{ 'media.title' | translate }}</span>
    </div>
    <div class="filters">
      <rh-dropdown
        [title]="'media.filters.mediaType.title' | translate"
        [items]="[
          { type: '', name: 'media.filters.unselectedText' | translate },
          {
            type: MediaTypeEnums.Image | translate,
            name: 'media.filters.mediaType.image' | translate,
          },
          {
            type: MediaTypeEnums.Video | translate,
            name: 'media.filters.mediaType.video' | translate,
          },
          {
            type: MediaTypeEnums.Slide | translate,
            name: 'media.filters.mediaType.slide' | translate,
          },
        ]"
        [(model)]="selectedMediaType"
        [invalidIfEmpty]="false"
        [blueMode]="true"
        (modelChange)="loadAssets()"
      ></rh-dropdown>
      <rh-textbox
        [title]="'media.filters.imageSearch.title' | translate"
        [placeholder]="'media.filters.imageSearch.placeholder' | translate"
        [(value)]="searchText"
        [blueMode]="true"
        [showSearchIcon]="true"
        (valueChange)="onSearchTextChanged()"
      ></rh-textbox>
      <rh-dropdown
        [title]="'media.filters.category' | translate"
        [(model)]="selectedCategory"
        [items]="availableFilterCategories"
        [invalidIfEmpty]="false"
        [blueMode]="true"
        (modelChange)="loadAssets()"
      ></rh-dropdown>
      <rh-dropdown
        [title]="'media.filters.byDate.title' | translate"
        [items]="[
          { name: 'media.filters.unselectedText' | translate, value: 0 },
          { name: 'media.filters.byDate.lastSevenDays' | translate, value: 7 },
          {
            name: 'media.filters.byDate.lastThirtyDays' | translate,
            value: 30,
          },
          { name: 'media.filters.byDate.lastYear' | translate, value: 365 },
        ]"
        [(model)]="selectedTimeFrame"
        [invalidIfEmpty]="false"
        [blueMode]="true"
        (modelChange)="loadAssets()"
      ></rh-dropdown>
      <rh-dropdown
        [title]="'media.filters.byUser' | translate"
        [items]="availableEditors"
        [(model)]="selectedEditor"
        [invalidIfEmpty]="false"
        [blueMode]="true"
        (modelChange)="loadAssets()"
      ></rh-dropdown>
      <div class="private-checkbox">
        <label>{{ 'media.filters.onlyPrivate' | translate }}</label>
        <rh-checkbox
          [(value)]="showOnlyPrivate"
          (valueChange)="loadAssets()"
          [blueMode]="true"
        ></rh-checkbox>
      </div>
      <hr class="separator" />
      <rh-dropdown
        [title]="'media.filters.sortBy.title' | translate"
        [items]="sortByOptions"
        [(model)]="sortBy"
        [invalidIfEmpty]="false"
        [blueMode]="true"
        (modelChange)="loadAssets()"
      ></rh-dropdown>
      <rh-dropdown
        [title]="'media.filters.sortDir.title' | translate"
        [items]="sortDirOptions"
        [(model)]="sortDir"
        [invalidIfEmpty]="false"
        [blueMode]="true"
        (modelChange)="loadAssets()"
      ></rh-dropdown>
    </div>
  </div>
  <div class="right">
    <div class="modal-toolbar">
      <div class="close-button" (click)="close()">
        <img class="close-icon" src="./assets/img/icons/close-gray.svg" />
      </div>
    </div>
    @let isLoading = !!(isLoading$ | async);
    @if (galleryView) {
      <div class="gallery-view">
        <div class="content">
          <div class="upload">
            <rh-media-drag-and-drop-area
              class="w-[600px]"
              (filesDroppedEvent)="onFilesDropped($event)"
            />
          </div>
          <div class="tiles-container">
            @if (!isLoading && tiles) {
              <div class="tiles">
                @for (tile of tiles; track tile.id) {
                  <rh-media-tile
                    class="tile"
                    [title]="tile.title"
                    [category]="tile.categoryName"
                    [author]="tile.author"
                    [uploaded]="tile.uploaded"
                    [private]="tile.private"
                    [showDeleteButton]="isCreator(tile.author)"
                    [url]="tile.displayImageUrl"
                    [mediaType]="getMediaType(tile.mimeType)"
                    (click)="select(tile)"
                    (deleteRequested)="onDeleteRequested(tile)"
                    (editRequested)="onEditRequested(tile)"
                    (viewRequested)="onViewRequested(tile)"
                  ></rh-media-tile>
                } @empty {
                  <div
                    class="p-4 mb-4 w-full text-sm text-yellow-700 bg-yellow-100 rounded-lg dark:bg-yellow-200 dark:text-yellow-800"
                    role="alert"
                  >
                    {{ 'media.noAssets' | translate }}
                  </div>
                }
              </div>
            } @else {
              <div class="w-full h-full flex items-center justify-center">
                <rh-loading></rh-loading>
              </div>
            }
          </div>
        </div>
      </div>
    } @else {
      <div
        class="grid grid-cols-1 grid-rows-1 overflow-y-auto my-[20px] scrollbar"
      >
        @if (!isLoading && availableCategories && user) {
          <rh-media-metadata-viewer
            [editMode]="editMode"
            [tiles]="tempUploadedTiles"
            [categories]="availableCategories"
            [userName]="user.email"
            (mediaDeclined)="onDeclineRequested($event)"
            (confirmationDone)="onConfirmationDone($event)"
            (saveCategoryClicked)="saveCategory($event)"
            (deleteCategoryClicked)="deleteCategory($event)"
          ></rh-media-metadata-viewer>
        } @else {
          <div class="w-full h-full flex items-center justify-center">
            <rh-loading></rh-loading>
          </div>
        }
      </div>
    }
  </div>
</div>
