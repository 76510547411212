import { createReducer, on } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import { MediaAsset } from '../../../core/models/media-asset.model';
import {
  addMediaAssetCategoryFailure,
  addMediaAssetCategorySuccess,
  clearMediaMessages,
  clearTemporaryMediaAssetsUrlUpdate,
  deleteMediaAsset,
  deleteMediaAssetCategorySuccess,
  deleteMediaAssetFailure,
  deleteMediaAssetSuccess,
  deleteTemporaryMediaAssetSuccess,
  loadMediaAssetReferencesSuccess,
  loadMediaAssetCategoriesSuccess,
  loadMediaAssets,
  loadMediaAssetsFailure,
  loadMediaAssetsSuccess,
  loadMediaEditors,
  loadMediaEditorsFailure,
  loadMediaEditorsSuccess,
  saveMediaAssetCategorySuccess,
  saveMediaAssets,
  saveMediaAssetsFailure,
  saveMediaAssetsSuccess,
  saveTemporaryMediaAssetProgress,
  saveTemporaryMediaAssetsUpdate,
  updateMediaBlobAssets,
  updateMediaBlobAssetsSuccess,
  updateMediaBlobAssetsFailure,
} from './media.actions';
import { initialState } from './media.state';

export const mediaReducer = createReducer(
  initialState,
  on(saveTemporaryMediaAssetProgress, (state, { progress }) => ({
    ...state,
    progressUpdate: progress,
  })),
  on(saveTemporaryMediaAssetsUpdate, (state, { assets }) => ({
    ...state,
    temporaryAssets: assets.map((asset) => ({
      ...asset,
      assetDisplayUrl: `${environment.backendApiUrl}/BlobAssets/${asset.assetUrl}/`,
    })),
    progressUpdate: undefined,
  })),
  on(deleteTemporaryMediaAssetSuccess, (state) => ({
    ...state,
    isLoading: false,
    progressUpdate: undefined,
  })),
  on(saveMediaAssets, (state) => ({
    ...state,
    progressUpdate: 0,
    assets: undefined,
  })),
  on(saveMediaAssetsSuccess, (state) => ({
    ...state,
    isLoading: false,
    progressUpdate: 100,
  })),
  on(saveMediaAssetsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
    temporaryAssets: [],
    progressUpdate: undefined,
  })),
  on(deleteMediaAsset, (state) => ({
    ...state,
    isLoading: true,
    progressUpdate: 0,
  })),
  on(deleteMediaAssetSuccess, (state) => ({
    ...state,
    isLoading: false,
    progressUpdate: 100,
  })),
  on(deleteMediaAssetFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
    temporaryAssets: [],
    progressUpdate: undefined,
  })),
  on(clearTemporaryMediaAssetsUrlUpdate, (state) => ({
    ...state,
    isLoading: false,
    temporaryAssets: [],
    progressUpdate: undefined,
  })),
  on(loadMediaAssets, (state) => ({
    ...state,
    isLoading: true,
    assets: undefined,
    error: '',
  })),
  on(loadMediaAssetsSuccess, (state, { assets }) => ({
    ...state,
    assets: assets.map((a) => transformImageUrl(a)),
    isLoading: false,
  })),
  on(loadMediaAssetsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),
  on(loadMediaEditors, (state) => ({
    ...state,
    isLoading: true,
    editors: [],
    error: '',
  })),
  on(loadMediaEditorsSuccess, (state, { editors }) => ({
    ...state,
    editors,
    isLoading: false,
  })),
  on(loadMediaEditorsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),
  on(clearMediaMessages, (state) => ({
    ...state,
    error: undefined,
    copySlideSuccessMessage: undefined,
  })),
  on(loadMediaAssetCategoriesSuccess, (state, { categories }) => ({
    ...state,
    mediaAssetCategories: categories,
  })),
  on(addMediaAssetCategorySuccess, (state, { category }) => ({
    ...state,
    mediaAssetCategories: [...(state.mediaAssetCategories || []), category],
  })),
  on(addMediaAssetCategoryFailure, (state, { error }) => ({
    ...state,
    mediaAssetCategories: [...(state.mediaAssetCategories || [])],
    error,
  })),
  on(saveMediaAssetCategorySuccess, (state, { category }) => ({
    ...state,
    mediaAssetCategories: state.mediaAssetCategories?.map((c) =>
      c.id === category.id ? category : c,
    ),
  })),
  on(deleteMediaAssetCategorySuccess, (state, { categoryId }) => ({
    ...state,
    mediaAssetCategories: state.mediaAssetCategories?.filter(
      (c) => c.id !== categoryId,
    ),
  })),
  on(loadMediaAssetReferencesSuccess, (state, { referencedCourses }) => ({
    ...state,
    referencedCourses,
  })),
  on(updateMediaBlobAssets, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(updateMediaBlobAssetsSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(updateMediaBlobAssetsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),
);

function transformImageUrl(tile: MediaAsset): MediaAsset {
  const imageUrl =
    tile.assetUrl?.indexOf('assets/img') === -1
      ? `${environment.backendApiUrl}/BlobAssets/${tile.assetUrl}`
      : tile.assetUrl;

  return {
    ...tile,
    assetDisplayUrl: imageUrl,
  };
}
