import { Routes } from '@angular/router';
import { authGuardFn } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { Modes } from './core/enums/modes';
import { adminGuard } from './core/guards/admin.guard';
import { approverGuard } from './core/guards/approver.guard';
import { editorGuard } from './core/guards/editor.guard';

export const routes: Routes = [
  {
    path: 'admin',
    loadComponent: () =>
      import(
        './features/admin/pages/admin-category/admin-category.component'
      ).then((m) => m.AdminCategoryComponent),
    canActivate: environment.useAuth ? [authGuardFn, adminGuard] : [],
  },
  {
    path: 'course-list',
    canActivate: environment.useAuth ? [authGuardFn] : [],
    children: [
      {
        path: 'viewer',
        loadComponent: () =>
          import('./features/course-list/course-list.component').then(
            (m) => m.CourseListComponent,
          ),
        data: { mode: Modes.VIEWER },
      },
      {
        path: 'editor',
        loadComponent: () =>
          import('./features/course-list/course-list.component').then(
            (m) => m.CourseListComponent,
          ),
        data: { mode: Modes.EDITOR },
        canActivate: environment.useAuth ? [editorGuard] : [],
      },
      {
        path: 'approver',
        loadComponent: () =>
          import('./features/course-list/course-list.component').then(
            (m) => m.CourseListComponent,
          ),
        data: { mode: Modes.APPROVER },
        canActivate: environment.useAuth ? [approverGuard] : [],
      },
      {
        path: '',
        redirectTo: 'viewer',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'course',
    canActivate: environment.useAuth ? [authGuardFn] : [],
    children: [
      {
        path: 'create',
        loadComponent: () =>
          import('./features/course-detail/course-detail.component').then(
            (m) => m.CourseDetailComponent,
          ),
        canActivate: environment.useAuth ? [editorGuard] : [],
      },
      {
        path: ':id/edit',
        loadComponent: () =>
          import('./features/course-detail/course-detail.component').then(
            (m) => m.CourseDetailComponent,
          ),
        canActivate: environment.useAuth ? [editorGuard] : [],
      },
      {
        path: ':id/edit/slide',
        loadComponent: () =>
          import('./features/slide-editor/slide-editor.component').then(
            (m) => m.SlideEditorComponent,
          ),
        canActivate: environment.useAuth ? [editorGuard] : [],
      },
      {
        path: ':id/view',
        loadComponent: () =>
          import('./features/course-viewer/course-viewer.component').then(
            (m) => m.CourseViewerComponent,
          ),
        canActivate: environment.useAuth ? [authGuardFn] : [],
      },
      {
        path: ':id/approve',
        loadComponent: () =>
          import('./features/course-viewer/course-viewer.component').then(
            (m) => m.CourseViewerComponent,
          ),
        data: { approverMode: true },
        canActivate: environment.useAuth ? [approverGuard] : [],
      },
    ],
  },
  {
    path: 'speaker-view',
    canActivate: environment.useAuth ? [authGuardFn] : [],
    loadComponent: () =>
      import('./features/speaker-view/speaker-view.component').then(
        (m) => m.SpeakerViewComponent,
      ),
  },
  {
    path: '**',
    redirectTo: 'course-list',
    pathMatch: 'full',
  },
];
