import { NgClass } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom, Subscription } from 'rxjs';
import { MediaTypeEnum } from '../../../../../core/enums/media-type.enum';
import { DialogService } from '../../../../../core/services/dialog/dialog.service';
import { SnackbarType } from '../../../../../core/services/snackbar/core/snackbar-type.enum';
import { SnackbarComponent } from '../../../../../core/services/snackbar/snackbar.component';
import { SnackbarService } from '../../../../../core/services/snackbar/snackbar.service';
import { slideFocusChanged } from '../../../../../shared/store/slide/slide.actions';
import { MediaLibraryModalComponent } from '../../../../media-library/media-library-modal/media-library-modal.component';
import { PlaceholderToolbarComponent } from '../core/toolbar/placeholder-toolbar.component';
import { UrlBasedPlaceholderBaseComponent } from '../core/url-based-placeholder-base.component';
import { ImagePlaceholderComponent } from '../image-placeholder/image-placeholder.component';
import { VideoPlaceholderComponent } from '../video-placeholder/video-placeholder.component';

@Component({
  selector: 'rh-media-placeholder',
  templateUrl: './media-placeholder.component.html',
  styleUrls: [
    './media-placeholder.component.scss',
    '../core/placeholder-base.component.scss',
  ],
  standalone: true,
  imports: [
    NgClass,
    ImagePlaceholderComponent,
    VideoPlaceholderComponent,
    PlaceholderToolbarComponent,
  ],
})
export class MediaPlaceholderComponent
  extends UrlBasedPlaceholderBaseComponent
  implements OnDestroy
{
  private dialog = inject(DialogService);
  private snackbar = inject(SnackbarService);
  private store = inject(Store);

  @ViewChild('imagePlaceholder', {
    read: ImagePlaceholderComponent,
    static: true,
  })
  public imagePlaceholder!: ImagePlaceholderComponent;

  @ViewChild('videoPlaceholder', {
    read: VideoPlaceholderComponent,
    static: true,
  })
  public videoPlaceholder!: VideoPlaceholderComponent;

  public get showImagePlaceholder(): boolean {
    return !!this.urlImage;
  }

  public get showVideoPlaceholder(): boolean {
    return !!this.urlVideo;
  }

  @Input() public urlImage!: string;
  @Input() public urlVideo!: string;
  @Input() public placeholderCode = ''; // Use this property to distinguish betweeen multiple media-placeholders on the same template
  @Input() public objectFit = 'contain';
  @Input() public enableZoom = true;

  public get showZoom(): boolean {
    return !!this.urlImage;
  }

  @Output() public urlImageChange: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() public urlVideoChange: EventEmitter<string> =
    new EventEmitter<string>();

  private subscriptions: Subscription = new Subscription();

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.zoomed) {
      this.zoomed = false;
      event.stopImmediatePropagation();
      return false;
    }

    return true;
  }

  constructor() {
    super();
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  public async selectMedia() {
    this.store.dispatch(slideFocusChanged({ slideFocused: true }));

    const dialogRef = this.dialog.open(MediaLibraryModalComponent, {
      data: { enableSelection: true, mediaType: MediaTypeEnum.Image },
    });

    const selectionResult = await lastValueFrom(dialogRef.afterClosed());
    if (selectionResult && selectionResult.payload) {
      if (selectionResult.type.startsWith('video')) {
        this.onVideoUrlChange(selectionResult.payload.url);
      } else if (selectionResult.type.startsWith('image')) {
        this.onImageUrlChange(selectionResult.payload.url);
      } else {
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: 'snackbar.wrongMediaType',
        });
      }
    }

    this.store.dispatch(slideFocusChanged({ slideFocused: false }));
  }

  public onImageUrlChange(url: string) {
    this.urlImageChange?.emit(url);
    this.urlVideoChange?.emit(''); // Remove the other asset
  }

  public onVideoUrlChange(url: string) {
    this.urlVideoChange?.emit(url);
    this.urlImageChange?.emit(''); // Remove the other asset
  }

  public onRemoveMediaUrl() {
    this.urlImageChange?.emit('');
    this.urlVideoChange?.emit('');
  }

  public override toggleZoom() {
    if (!this.enableZoom) {
      return;
    }

    this.zoomed = !this.zoomed;
    super.onZoomChanged(this.zoomed);
  }
}
