<div
  class="media-drag-and-drop-area-container"
  [ngClass]="{ 'drop-area-ctive': active }"
  rhDdragAndDropFiles
  (filesDropped)="onFilesDropped($event)"
  (activeChange)="onStatusChanged($event)"
  (click)="selectFiles()"
>
  <span class="upload-button">
    <img class="img img-blue" src="/assets/icons/Icon_Upload_Blau.svg" />
    <img class="img img-green" src="/assets/icons/Icon_Upload_Gruen.svg" />
  </span>
  <span class="drag-area">{{ 'dragAndDrop.text' | translate }}</span>
  <span class="plus-button">
    <input
      #multiFileUpload
      class="file-input"
      type="file"
      accept=".png, .jpg, .jpeg, .gif, .webp, .mp4"
      [multiple]="multiple"
      (change)="onFilesSelected($event)"
    />
    <button class="img-button">
      <img class="img" src="/assets/img/buttons/add-blue.svg" />
    </button>
  </span>
</div>

@if (loadingOrSaving$ | async) {
  <div class="loading-spinner-container loading-spinner-container-background">
    <rh-loading></rh-loading>
  </div>
}
