import { NgStyle } from '@angular/common';
import { Component, HostListener, inject, Inject } from '@angular/core';
import { DialogRef } from '../../../core/services/dialog/dialog-ref';
import { DIALOG_DATA } from '../../../core/services/dialog/dialog-tokens';
import { IMediaPreviewModalData } from './media-preview-modal-data';

@Component({
  selector: 'rh-media-preview-modal',
  templateUrl: './media-preview-modal.component.html',
  styleUrls: ['./media-preview-modal.component.scss'],
  standalone: true,
  imports: [NgStyle],
})
export class MediaPreviewModalComponent {
  private dialogRef = inject(DialogRef);

  public url = '';
  public fallbackImageUrl = '';

  public isVideo = false;

  constructor(@Inject(DIALOG_DATA) public data: IMediaPreviewModalData) {
    this.url = data.url;
    this.isVideo = this.url?.indexOf('.mp4') > 0 || false;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.close();
      event.stopImmediatePropagation();
      return false;
    }

    return true;
  }

  public async close(): Promise<void> {
    this.dialogRef.close();
  }
}
