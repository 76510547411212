import { HttpClient, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private http = inject(HttpClient);

  SaveTemporaryAsset(files: File[]): Observable<HttpEvent<object>> {
    const formData = new FormData();

    formData.append('mainFile', files[0]);
    for (let i = 1; i < files.length; i++) {
      const file = files[i];
      formData.append(`file${i}`, file);
    }

    return this.http
      .post(
        `${environment.backendApiUrl}/SaveTemporaryBlobAsset?code=${environment.apiKey}`,
        formData,
        {
          reportProgress: true,
          observe: 'events',
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveThumbnails(slideId: number, thumbnail: string): Observable<object> {
    return this.http
      .post(
        `${environment.backendApiUrl}/SaveThumbnails?code=${environment.apiKey}`,
        { slideId: slideId, thumbnail: thumbnail },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
