<div class="w-full">
  @if (title) {
    <div
      class="control-title text-[11px] font-brand-regular text-brand-blue-400 uppercase"
    >
      {{ title | translate }}
    </div>
  }
  <div
    class="relative w-full font-brand-regular text-lg rounded-md text-brand-blue-600"
    [ngClass]="{
      'border-[#ff0000]': !model,
      'bg-brand-blue-600 rounded-b-none text-white border-none m-0':
        listVisible,
      'border border-[#cccccc] hover:border-brand-blue-600 hover:border-2 hover:-m-[1px]':
        !listVisible,
    }"
  >
    <button
      class="flex w-full items-center justify-between h-[50px] px-3"
      [disabled]="disabled"
      (click)="toggle()"
    >
      @if (listVisible) {
        <p>{{ 'media.filters.choose-a-category' | translate }}</p>
      } @else {
        <p class="uppercase">{{ model?.name || '' | translate }}</p>
      }
      <div class="w-3 transition duration-300">
        <img
          [ngClass]="{ 'rotate-180 transition duration-300': listVisible }"
          [src]="
            listVisible
              ? './assets/img/buttons/dropdown-white.svg'
              : './assets/img/buttons/dropdown.svg'
          "
        />
      </div>
    </button>

    @if (listVisible) {
      <div
        class="absolute top-full z-50 flex flex-col gap-2 left-0 bg-brand-blue-600 rounded-b-md text-white w-full max-h-[300px] font-brand-regular text-lg"
      >
        <div class="flex items-center justify-between px-3">
          <rh-textbox
            [placeholder]="
              'media.filters.categorySearch.placeholder' | translate
            "
            [(value)]="searchText"
            [blueMode]="true"
            [showSearchIcon]="true"
            (valueChange)="onSearchTextChanged()"
          ></rh-textbox>
          <button
            class="h-8 flex justify-center items-center w-12 border border-[#cccccc] rounded bg-brand-blue-600 hover:bg-brand-blue-350"
            (click)="addCategory()"
          >
            <img src="/assets/img/buttons/add_white.svg" class="h-5" />
          </button>
        </div>
        <div class="flex flex-col options-list overflow-y-scroll">
          @for (category of filteredItems; track category.id) {
            <div
              [ngClass]="{ 'bg-brand-blue-500': isSelected(category) }"
              class="flex h-[50px] shrink-0 px-3 overflow-hidden hover:bg-brand-blue-500"
              [title]="category.name"
            >
              <div
                class="h-full w-full py-2 border-t flex items-center justify-between"
              >
                @if (editingCategory && editingCategory.id === category.id) {
                  <input
                    type="text"
                    [(ngModel)]="editingCategory.name"
                    class="h-8 border border-[#cccccc] hover:border-white focus:border-white bg-transparent focus:bg-transparent focus:outline-none"
                  />
                } @else {
                  <button
                    class="w-full h-full items-center flex grow-0"
                    (click)="select(category)"
                  >
                    <p class="whitespace-nowrap text-ellipsis">
                      {{ category.name }}
                    </p>
                  </button>
                }
                @if (
                  userName.toLowerCase() === category.created_by.toLowerCase()
                ) {
                  <div class="flex gap-2 shrink-0">
                    @if (editingCategory) {
                      <button
                        (click)="cancelEdit()"
                        class="h-8 w-12 flex justify-center items-center border border-[#cccccc] rounded bg-brand-blue-600 hover:bg-brand-blue-350"
                      >
                        <img
                          src="/assets/img/buttons/Reishauer_Icons_Close.svg"
                          alt="Cancel"
                          class="h-5"
                        />
                      </button>
                      <button
                        (click)="saveCategory()"
                        class="h-8 w-12 flex justify-center items-center border border-[#cccccc] rounded bg-brand-blue-600 hover:bg-brand-blue-350"
                      >
                        <img
                          src="/assets/img/buttons/Reishauer_Icons_Checkmark.svg"
                          alt="Save"
                          class="h-5"
                        />
                      </button>
                    } @else {
                      <button
                        (click)="deleteCategory(category.id)"
                        class="h-8 w-12 flex justify-center items-center border border-[#cccccc] rounded bg-brand-blue-600 hover:bg-brand-blue-350"
                      >
                        <img
                          src="/assets/img/buttons/Reishauer_Icons-delete.svg"
                          alt="Delete"
                          class="h-5"
                        />
                      </button>
                      <button
                        (click)="editCategory(category)"
                        class="h-8 w-12 flex justify-center items-center border border-[#cccccc] rounded bg-brand-blue-600 hover:bg-brand-blue-350"
                      >
                        <img
                          src="/assets/img/buttons/Reishauer_Icon-Edit.svg"
                          alt="Edit"
                          class="h-5"
                        />
                      </button>
                    }
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
