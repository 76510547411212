<div class="w-full" [ngClass]="{ 'text-white/50': blueMode }">
  @if (title) {
    <div
      class="control-title text-[11px] font-brand-regular text-brand-blue-400 uppercase"
      [ngClass]="{ 'text-[#bbc6de]': blueMode }"
    >
      {{ title | translate }}
    </div>
  }
  <div class="flex relative flex-col">
    <button
      class="font-brand-regular text-lg justify-between px-3 flex items-center uppercase h-[50px] rounded-md border border-[#cccccc] text-brand-blue-600 hover:border-brand-blue-600 hover:border-2 hover:-mx-[1px]"
      [ngClass]="{
        'border-[#ff0000]': !model && invalidIfEmpty,
        'text-white border-[#7b91bf] bg-transparent hover:border-[#adbfe6] hover:bg-[#345394]':
          blueMode,
      }"
      [disabled]="disabled"
      (click)="toggle()"
      (focusout)="onFocusOutEvent()"
    >
      <p class="overflow-hidden text-ellipsis whitespace-nowrap">
        {{ model?.name || '' | translate }}
      </p>
      <div class="w-3 transition duration-300">
        @if (blueMode) {
          <img
            [ngClass]="{ 'rotate-180 transition duration-300': listVisible }"
            src="./assets/img/buttons/dropdown-white.svg"
          />
        } @else {
          <img
            [ngClass]="{ 'rotate-180 transition duration-300': listVisible }"
            src="./assets/img/buttons/dropdown.svg"
          />
        }
      </div>
    </button>

    @if (listVisible) {
      <div
        class="options-list absolute z-50 top-full left-0 bg-brand-blue-600 rounded-md text-white w-full overflow-y-scroll max-h-[300px] font-brand-regular text-lg"
        [ngClass]="{
          'bg-[#345394]': blueMode,
        }"
      >
        @for (item of items; track item) {
          <div
            [ngClass]="{ 'bg-brand-blue-500': isSelected(item) }"
            class="overflow-hidden whitespace-nowrap px-3 py-2 hover:bg-brand-blue-500 cursor-pointer text-ellipsis uppercase"
            [title]="item.name | translate"
            (click)="select(item)"
          >
            {{ item.name | translate }}
          </div>
        }
      </div>
    }
  </div>
</div>
