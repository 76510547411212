import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  debounceTime,
  distinctUntilChanged,
  from,
  map,
  Subject,
  Subscription,
} from 'rxjs';
import { MediaAssetCategory } from '../../models/media-asset-category.model';
import { TextboxComponent } from '../textbox/textbox.component';

@Component({
  selector: 'rh-media-asset-category-dropdown',
  imports: [CommonModule, TranslatePipe, TextboxComponent, FormsModule],
  templateUrl: './media-asset-category-dropdown.component.html',
  styleUrl: './media-asset-category-dropdown.component.scss',
})
export class MediaAssetCategoryDropdownComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @Input()
  public model?: MediaAssetCategory;

  @Input()
  public title?: string;

  filteredItems: MediaAssetCategory[] = [];

  private _categories!: MediaAssetCategory[];
  @Input({ required: true })
  public set categories(v: MediaAssetCategory[]) {
    this._categories = v;
    this.filteredItems = v ?? [];
  }
  public get categories(): MediaAssetCategory[] {
    return this._categories;
  }

  @Input()
  public disabled = false;
  @Input({ required: true })
  public userName!: string;

  @Output()
  private modelChange: EventEmitter<MediaAssetCategory> =
    new EventEmitter<MediaAssetCategory>();

  @Output()
  private saveClicked = new EventEmitter<MediaAssetCategory>();
  @Output()
  private deleteClicked = new EventEmitter<number>();

  public listVisible = false;
  public searchTextChange$ = new Subject<string>();
  searchText = '';

  editingCategory: MediaAssetCategory | null = null;

  ngOnInit(): void {
    this.subscriptions.add(
      this.searchTextChange$
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          map(() =>
            from(
              (this.filteredItems =
                this.categories?.filter((item) =>
                  item.name
                    .toLowerCase()
                    .includes(this.searchText.toLowerCase()),
                ) ?? []),
            ),
          ),
        )
        .subscribe(),
    );
  }

  onSearchTextChanged() {
    this.searchTextChange$.next(this.searchText);
  }

  public async toggle() {
    this.listVisible = !this.listVisible;
  }

  public async select(item: MediaAssetCategory) {
    this.listVisible = false;

    if (this.model === item) {
      return;
    }

    this.model = item;
    this.modelChange?.emit(item);
  }

  isSelected(item: MediaAssetCategory): boolean {
    return this.model === item;
  }

  public onFocusOutEvent() {
    // We need to give the click-event precedence, otherwise it will not be fired
    setTimeout(() => {
      this.listVisible = false;
    }, 200);
  }

  addCategory() {
    this.editingCategory = {
      id: 0,
      name: '',
      created_by: this.userName,
    };
    this.categories = [this.editingCategory, ...this.categories];
  }

  cancelEdit() {
    if (this.editingCategory?.id === 0) {
      this.categories = this.categories.filter(
        (c) => c.id !== this.editingCategory?.id,
      );
    }
    this.editingCategory = null;
  }

  saveCategory() {
    if (!this.editingCategory || !this.editingCategory.name) {
      return;
    }
    this.saveClicked.emit(this.editingCategory);
    this.editingCategory = null;
  }

  deleteCategory(categoryId: number) {
    this.deleteClicked.emit(categoryId);
  }

  editCategory(category: MediaAssetCategory) {
    this.editingCategory = Object.assign({}, category);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
