import { CommonModule, DatePipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { lastValueFrom } from 'rxjs';
import { ButtonComponent } from '../../../core/components/button/button.component';
import { CheckboxComponent } from '../../../core/components/checkbox/checkbox.component';
import { ConfirmCancelDialogComponent } from '../../../core/components/confirm-cancel-dialog/confirm-cancel-dialog.component';
import { IConfirmCancelDialogData } from '../../../core/components/confirm-cancel-dialog/core/confirm-cancel-dialog-data.interface';
import { MediaAssetCategoryDropdownComponent } from '../../../core/components/media-asset-category-dropdown/media-asset-category-dropdown.component';
import { TextboxComponent } from '../../../core/components/textbox/textbox.component';
import { MediaAssetCategory } from '../../../core/models/media-asset-category.model';
import { MediaTile } from '../../../core/models/media-tile.model';
import { DialogService } from '../../../core/services/dialog/dialog.service';
import { MediaDragAndDropAreaComponent } from '../media-drag-and-drop-area/media-drag-and-drop-area.component';
import {
  loadMediaAssetReferences,
  updateMediaBlobAssets,
} from '../../../shared/store/media/media.actions';
import { MediaBlobAssets } from '../../../core/models/media-blob-assets.model';
import { selectReferencedSlides } from '../../../shared/store/media/media.selectors';

@Component({
  selector: 'rh-media-metadata-viewer',
  templateUrl: './media-metadata-viewer.component.html',
  styleUrls: ['./media-metadata-viewer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CommonModule,
    TextboxComponent,
    MediaAssetCategoryDropdownComponent,
    CheckboxComponent,
    ButtonComponent,
    DatePipe,
    TranslateModule,
    MediaDragAndDropAreaComponent,
  ],
})
export class MediaMetadataViewerComponent implements OnInit {
  private store = inject(Store);
  private dialog = inject(DialogService);

  protected referenceCourses$ = this.store.select(selectReferencedSlides);

  @Input()
  public editMode = false;

  @Input({ required: true })
  public categories!: MediaAssetCategory[];

  @Input({ required: true })
  public userName!: string;

  private _tiles: MediaTile[] = [];
  @Input()
  public get tiles(): MediaTile[] {
    return this._tiles;
  }

  public set tiles(value: MediaTile[]) {
    this._tiles = value;
    this.currentTileIndex = 0;
    if (value.length) {
      this.currentTile = this._tiles[0];
      this.ensureCategory(this._tiles);
    }
  }

  protected files: File[] = [];
  protected showConfirmationMenu = false;
  protected randomRefreshKey = this.generateRandomRefreshKey();

  // Form fields
  public currentTile?: MediaTile;
  public currentTileIndex = 0;

  @Output()
  public mediaDeclined: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  public confirmationDone: EventEmitter<MediaTile[]> = new EventEmitter<
    MediaTile[]
  >();

  @Output()
  private saveCategoryClicked = new EventEmitter<MediaAssetCategory>();
  @Output()
  private deleteCategoryClicked = new EventEmitter<number>();

  public ngOnInit(): void {
    this.loadMediaAssetReferences();
  }

  protected loadMediaAssetReferences() {
    if (!this.currentTile) {
      return;
    }
    this.store.dispatch(
      loadMediaAssetReferences({
        imageUrl: this.currentTile.imageUrl,
      }),
    );
  }

  protected get isVideo(): boolean {
    return (
      (this.currentTile &&
        this.currentTile.imageUrl &&
        this.currentTile.imageUrl.indexOf('.mp4') > 0) ||
      false
    );
  }

  protected get isValid(): boolean {
    return (
      !!this.currentTile &&
      !!this.currentTile.title &&
      this.currentTile.title.length > 0 &&
      this.currentTile.categoryId > 0
    );
  }

  public generateRandomRefreshKey() {
    return Math.random().toString(36).substring(2);
  }

  public async onCategoryChanged(category: MediaAssetCategory) {
    if (!this.currentTile) {
      return;
    }
    localStorage.setItem(
      'last-category',
      JSON.stringify({ id: category.id, name: category.name }),
    );
    this.currentTile.categoryId = category.id;
  }

  protected async dismissAsset(tile: MediaTile) {
    if (!tile.imageUrl) {
      return;
    }

    this.mediaDeclined?.emit(tile.id);

    if (!this.moveNext(false, tile.imageUrl)) {
      return;
    }

    this.confirmationDone?.emit(this.tiles.filter((f) => f.selected));
  }

  protected async confirmAsset(url?: string) {
    if (!this.moveNext(true, url)) {
      return;
    }

    this.confirmationDone?.emit(this.tiles.filter((f) => f.selected));
  }

  protected async cancel() {
    this.confirmationDone?.emit([]);
  }

  protected async save(tile: MediaTile) {
    this.confirmationDone?.emit([tile]);
  }

  //
  protected moveNext(select: boolean, url?: string) {
    if (!url || !this.currentTile) {
      return true;
    }

    this.currentTile.selected = select;

    if (this.currentTileIndex < this.tiles.length - 1) {
      this.currentTile = this.tiles[++this.currentTileIndex];
      return false;
    }

    this.currentTile = undefined;
    this.currentTileIndex = 0;

    return true;
  }

  protected ensureCategory(mediaTiles: MediaTile[]) {
    // If the tile does not have a category, we will use the last one the user selected by design
    const lastCategory = localStorage.getItem('last-category');
    if (!lastCategory) {
      return;
    }

    const parsedCategory = JSON.parse(lastCategory) as MediaAssetCategory;

    for (let i = 0; i < mediaTiles.length; i++) {
      const mediaTile = mediaTiles[i];

      if (!mediaTile.category) {
        mediaTile.category = parsedCategory;
        mediaTile.categoryId = mediaTile.category.id;
        mediaTile.categoryName = mediaTile.category.name;
      }
    }
  }

  protected saveCategory(category: MediaAssetCategory) {
    this.saveCategoryClicked.emit(category);
  }

  protected async deleteCategory(categoryId: number) {
    const dialogRef = this.dialog.open(ConfirmCancelDialogComponent, {
      data: {
        title: 'popups.deleteCategory.title',
        text: 'popups.deleteCategory.message',
      } as IConfirmCancelDialogData,
    });

    const confirmed = (await lastValueFrom(dialogRef.afterClosed())).decision;

    if (!confirmed) {
      return;
    }

    this.deleteCategoryClicked.emit(categoryId);
  }

  // We only expect one file to be dropped in edit mode
  // Guaranteed by the `[multiple]="false"` attribute in the template

  protected onFilesDropped(files: File[]): void {
    this.files = files;
    this.showConfirmationMenu = true;
  }

  protected confirmFileDrop(): void {
    if (
      !this.currentTile ||
      !this.currentTile.imageUrl ||
      this.files.length !== 1
    ) {
      return;
    }

    const modifiedFiles: File[] = [
      new File([this.files[0]], this.currentTile.imageUrl, {
        type: this.currentTile.mimeType,
      }),
    ];

    this.store.dispatch(
      updateMediaBlobAssets({
        assets: {
          files: modifiedFiles,
        } as MediaBlobAssets,
      }),
    );

    this.showConfirmationMenu = false;
  }

  protected dismissFileDrop(): void {
    this.showConfirmationMenu = false;
  }
}
