import { HttpEventType } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { SnackbarType } from '../../../core/services/snackbar/core/snackbar-type.enum';
import { SnackbarComponent } from '../../../core/services/snackbar/snackbar.component';
import { SnackbarService } from '../../../core/services/snackbar/snackbar.service';
import { AssetService } from '../../services/asset/asset.service';
import { SlideService } from '../../services/slide/slide.service';
import { setLoadingInactive } from '../core/core.actions';
import { loadCourse } from '../course/course.actions';
import {
  addSlide,
  addSlideFailure,
  addSlideSuccess,
  deleteSlide,
  deleteSlideFailure,
  deleteSlideSuccess,
  pasteSlide,
  pasteSlideFailure,
  pasteSlideSuccess,
  saveSlides,
  saveSlidesFailure,
  saveSlidesSuccess,
  saveTemporarySlideAsset,
  saveTemporarySlideAssetProgress,
  saveTemporarySlideAssetsFailure,
  saveTemporarySlideAssetUrlUpdate,
} from './slide.actions';

@Injectable()
export class SlideEffects {
  private slideService = inject(SlideService);
  private assetService = inject(AssetService);
  private actions$ = inject(Actions);
  private snackbar = inject(SnackbarService);

  addSlideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSlide),
      exhaustMap((action) =>
        this.slideService.AddSlide(action.slide, action.courseId).pipe(
          map(() =>
            addSlideSuccess({
              courseId: action.courseId,
              versionNumber: action.versionNumber,
            }),
          ),
          catchError((error) =>
            of(
              addSlideFailure({
                error: error.error,
                courseId: action.courseId,
                versionNumber: action.versionNumber,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  addSlideSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSlideSuccess),
      exhaustMap((action) => [
        loadCourse({
          id: action.courseId,
          versionNumber: action.versionNumber,
        }),
      ]),
    ),
  );

  addSlideFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addSlideFailure),
      tap(() => {
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: `snackbar.addingSlideFailed`,
        });
      }),
      exhaustMap((action) => [
        loadCourse({
          id: action.courseId,
          versionNumber: action.versionNumber,
        }),
      ]),
    ),
  );

  saveSlidesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSlides),
      switchMap((action) =>
        this.slideService
          .SaveSlides(
            action.newOrChangedSlides,
            action.courseId,
            action.thumbnail,
            action.thumbnailSlideId,
          )
          .pipe(
            map(() =>
              saveSlidesSuccess({
                courseId: action.courseId,
                versionNumber: action.versionNumber,
              }),
            ),
            catchError((error) =>
              of(saveSlidesFailure({ error: error.error })),
            ),
          ),
      ),
    ),
  );

  saveSlidesSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSlidesSuccess),
      tap(() => {
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Success,
          text: `snackbar.slidesSaved`,
        });
      }),
      switchMap((action) => {
        return [
          setLoadingInactive(),
          loadCourse({
            id: action.courseId,
            versionNumber: action.versionNumber,
          }),
        ];
      }),
    ),
  );

  saveSlidesFailureEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveSlidesFailure),
        tap(() => {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `snackbar.savingSlidesFailed`,
          });
          location.reload();
        }),
      ),
    { dispatch: false },
  );

  pasteSlideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pasteSlide),
      exhaustMap((action) =>
        this.slideService
          .PasteSlide(action.slideId, action.courseVersionId, action.position)
          .pipe(
            map(() =>
              pasteSlideSuccess({
                courseId: action.courseId,
                versionNumber: action.versionNumber,
              }),
            ),
            catchError((error) =>
              of(pasteSlideFailure({ error: error.error })),
            ),
          ),
      ),
    ),
  );

  pasteSlideSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pasteSlideSuccess),
      tap(() => {
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Success,
          text: `snackbar.slidePasted`,
        });
      }),
      exhaustMap((action) => [
        loadCourse({
          id: action.courseId,
          versionNumber: action.versionNumber,
        }),
        setLoadingInactive(),
      ]),
    ),
  );

  pasteSlideFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pasteSlideFailure),
      tap(() => {
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: `snackbar.pastingSlideFailed`,
        });
        window.location.reload();
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );

  saveTemporarySlideAssetEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveTemporarySlideAsset),
      exhaustMap((action) => {
        return this.assetService.SaveTemporaryAsset(action.asset.files).pipe(
          map((event) => {
            if (event.type == HttpEventType.UploadProgress && event.total) {
              const progress = Math.round((100 / event.total) * event.loaded);
              return saveTemporarySlideAssetProgress({
                progress: Math.min(progress, 98), // 100% is received when we get back the url, see below
                placeholderCode: action.asset.placeholderCode,
              });
            }

            // Final event when 100% & Success is reported
            if (event.type == HttpEventType.Response) {
              saveTemporarySlideAssetProgress({
                progress: 100,
                placeholderCode: action.asset.placeholderCode,
              });

              return saveTemporarySlideAssetUrlUpdate({
                urls: (event.body as { result: string[] })?.result,
                placeholderCode: action.asset.placeholderCode,
              });
            }

            // Fallback/placeholder case, which is not represented in the UI (but we need to return something here...)
            return saveTemporarySlideAssetProgress({
              progress: 0,
              placeholderCode: action.asset.placeholderCode,
            });
          }),
          catchError((error) =>
            of(saveTemporarySlideAssetsFailure({ error: error.error })),
          ),
        );
      }),
    ),
  );

  deleteSlideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSlide),
      exhaustMap((action) =>
        this.slideService.DeleteSlide(action.slide).pipe(
          exhaustMap(() => [
            deleteSlideSuccess({
              courseId: action.courseId,
              versionNumber: action.versionNumber,
            }),
          ]),
          catchError((error) => of(deleteSlideFailure({ error: error.error }))),
        ),
      ),
    ),
  );

  deleteSlideSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSlideSuccess),
      tap(() => {
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Success,
          text: `snackbar.slideDeleted`,
        });
      }),
      exhaustMap((action) => [
        loadCourse({
          id: action.courseId,
          versionNumber: action.versionNumber,
        }),
        setLoadingInactive(),
      ]),
    ),
  );

  deleteSlideFailureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSlideFailure),
      tap(() => {
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: `snackbar.deletingSlideFailed`,
        });
      }),
      exhaustMap(() => [setLoadingInactive()]),
    ),
  );
}
